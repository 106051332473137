.react-grid-item {
    display: flex;
    flex-direction: column;
    /*overflow-y: auto;*/
}


.drag {
    cursor: move;
    /*
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: grab;  */
}

.ui.lob-shadow--raised.segments,
.ui.lob-shadow--raised.segment,
.lob-shadow--raised {
    box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.08);
}

.lob-shadow--normal {
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
}