/* This helps to display Lookups inside modals
Maybe it breaks some layout somewhere, we will see :)
*/
/* Breaks scrolling :(
.slds-modal__content {
    overflow: visible;
    overflow-y: inherit;
}
*/

html {
    background-color: white;
}

body {
    /* Hide horizontal scrollbar that can cause usability issues with double x scroll bars in combination with ant table*/
    overflow-x:hidden !important;
}

.lob-col-overflow {
    overflow : scroll;
}

.lob-no-wrap {
    white-space: nowrap !important;
}

.ant-table-tbody > tr > td {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #f3f3f3;
}

.lobaro-side-menu-collapsed > div {
    padding-left: 10px !important;
    width: 38px !important;
}

.lobaro-side-menu-expanded {
    padding-left: 9px !important;
    padding-right: 0 !important;
    background-color: white !important;
}

.lobaro-side-menu-expanded > .ant-menu-title-content {
    /* parent flex box to hold button elements*/
    display: flex;
}

.lobaro-side-menu-collapse-button {
    /* grows itself to get maximum space in the button group */
    flex-grow: 10;
    /* embed the actual button inside a new flex-box and make it right aligned*/
    display: flex;
    justify-content: flex-end;
}

.w-md-editor-text-input {
    -webkit-text-fill-color: black !important;
}


/*.react-resizable {*/
/*    position: relative;*/
/*    background-clip: padding-box;*/
/*}*/

.react-resizable-handle {
    position: absolute;
    inset-inline-end: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}
