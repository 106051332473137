.coll-str {
    word-break: break-all;
    vertical-align: baseline;
}

.coll-button {
    padding: 0;
    border: none;
    font-size: inherit;
    height: inherit;
    line-height: inherit;
    border-radius: 0;
    vertical-align: baseline;
}