
.pre {
    font-family: monospace;
    white-space: pre;
    overflow: auto;

}

/* Light Theme */
/*BG: #d7d4f0*/
.json {
    background-color: #f3f2f2;
    color: #514f4d;
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
    white-space: pre-wrap; /*Allow to wrap long lines!*/
    overflow-wrap: break-word;
    max-height: 300px;

    /*Might be needed to not break UI*/
    /*width: 800px;*/
}

.json .string {
    color: #249d7f;
}

.json .number {
    color: #ed5c65;
}

.json .boolean {
    color: #219;
}

.json .null {
    color: #219;
}

.json .key {
    color: #514f4d;
}

/*pre.json .key { color: #6ce890; }*/

/* Dark Theme */
.json-dark {
    background-color: #39464e;
    outline: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
}

/**/
.json-dark .string {
    color: #f5eea2;
}

.json-dark .number {
    color: #ed6e55;
}

.json-dark .boolean {
    color: #7561ff;
}

.json-dark .null {
    color: #7561ff;
}

.json-dark .key {
    color: #6ce890;
}
